var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__img",
    class: `energy__img__${_vm.loginReward.coinType}`
  }, [_vm.loginReward.coinType == 0 ? [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-big-coin.png`),
      "alt": ""
    }
  })] : _vm.loginReward.coinType == 1 ? [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/earn-logo.png`),
      "alt": ""
    }
  })] : _vm.loginReward.coinType == 2 ? [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/tap.png`),
      "alt": ""
    }
  })] : _vm._e()], 2), _c('div', {
    staticClass: "energy__intro",
    class: `energy__intro__${_vm.loginReward.coinType}`
  }, [_vm._v(" " + _vm._s(_vm.loginReward.reason) + " "), _c('br'), _vm.loginReward.coinType == 0 ? [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  })] : _vm.loginReward.coinType == 1 ? [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/earn-key.png`),
      "alt": ""
    }
  })] : _vm.loginReward.coinType == 2 ? [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/tap.png`),
      "alt": ""
    }
  })] : _vm._e(), _c('span', [_vm._v(_vm._s(_vm._f("toThousands")(_vm.loginReward.reward || 0)))])], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };