/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./memetownIndex.vue?vue&type=template&id=e4592978&scoped=true"
import script from "./memetownIndex.vue?vue&type=script&lang=js"
export * from "./memetownIndex.vue?vue&type=script&lang=js"
import style0 from "./memetownIndex.vue?vue&type=style&index=0&id=e4592978&prod&lang=scss"
import style1 from "./memetownIndex.vue?vue&type=style&index=1&id=e4592978&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4592978",
  null
  
)

export default component.exports