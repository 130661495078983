var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "airdropTap page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/task-detail-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "top-box"
  }, [_c('div', {
    staticClass: "top-box-title"
  }, [_vm.isProgOk ? [_vm.airdropTapInfo.enabled && _vm.airdropTapInfo.unclaimedAmount ? [_vm._v(" " + _vm._s(_vm.$lang('Available airdrop')) + " ")] : _vm.airdropTapInfo.enabled ? [_vm._v(" " + _vm._s(_vm.$lang('I have received airdrop')) + " ")] : [_vm._v(" " + _vm._s(_vm.$lang('Airdrop')) + " ")]] : [_vm._v(" " + _vm._s(_vm.$lang('Airdrop')) + " ")]], 2), _c('div', {
    staticClass: "top-box-num"
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/icons/tap.png"
    }
  }), _vm.isProgOk ? [_vm.airdropTapInfo.enabled && _vm.airdropTapInfo.unclaimedAmount ? [_vm._v(" +" + _vm._s(_vm._f("toThousands")(_vm.airdropTapInfo.unclaimedAmount.toFixed(2))) + " "), _c('span', [_vm._v("$TAP")])] : [_vm._v(" " + _vm._s(_vm._f("toThousands")((_vm.airdropTapInfo.totalClaimedAmount || 0.00).toFixed(2))) + " "), _c('span', [_vm._v("$TAP")])]] : [_vm._v(" Calculating... ")]], 2)]), _c('div', {
    staticClass: "btns"
  }, [_vm.isProgOk ? [_vm.airdropTapInfo.enabled && _vm.airdropTapInfo.unclaimedAmount ? [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onClaim();
      }
    }
  }, [_vm._v(" Claim ")])] : _vm.airdropTapInfo.totalClaimedAmount ? [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onDetails();
      }
    }
  }, [_vm._v(" Details ")])] : [_c('div', {
    staticClass: "btn dis"
  }, [_vm._v(" Claim ")])]] : [_vm._v("   ")]], 2), _vm.isProgOk && _vm.currentFormattedDate >= 20240925 ? _c('div', {
    staticClass: "countDown"
  }, [_vm._v(" Get Ready for the Next Round Airdrop!"), _c('br'), _vm._v(" Stay Active and Keep Earning! ")]) : _vm.isProgOk && _vm.airdropTapInfo.enabled && !_vm.airdropTapInfo.unclaimedAmount && _vm.countDown ? _c('div', {
    staticClass: "countDown"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/time.png`)
    }
  }), _vm._v(" Next collection " + _vm._s(_vm._f("formatHour")(_vm.countDown)) + " ")]) : _vm._e(), _vm.airdropTapInfo.enabled && _vm.airdropTapInfo.unclaimedAmount ? _c('div', {
    staticClass: "calendar-box"
  }, _vm._l(_vm.dateInfos, function (item, index) {
    return _c('div', {
      staticClass: "item",
      class: !item.date ? 'item-dblue' : item.claimed ? 'item-gray' : ''
    }, [_vm._m(0, true), _c('div', {
      staticClass: "d"
    }, [_vm._v(_vm._s(_vm._f("formatDateStr")(item.date, 'day')))]), _c('div', {
      staticClass: "m"
    }, [_vm._v(_vm._s(_vm._f("formatDateStr")(item.date, 'month')))])]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "fs"
  }, [_c('div', {
    staticClass: "fs-title"
  }, [_vm._v(_vm._s(_vm.$lang('Core Points')))]), _c('div', {
    staticClass: "fs-list"
  }, [_c('div', {
    staticClass: "fs-item"
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[0].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tap-icon1.png`)
    }
  })]), _vm._m(1), _vm.progressList[0].isOk ? _c('div', {
    staticClass: "item-extend"
  }, [_vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.hourEearnings || 0, 2)) + " "), _c('div', {
    staticClass: "svg",
    on: {
      "click": function ($event) {
        return _vm.onTip(0);
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-sigh"
    }
  })], 1)]) : _vm._e()])]), _c('div', {
    staticClass: "fs-item"
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[1].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon item-icon-2"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tap-icon2.png`)
    }
  })]), _vm._m(2), _vm.progressList[1].isOk ? _c('div', {
    staticClass: "item-extend"
  }, [_vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.userData.total_stock || 0, 2)) + " "), _c('div', {
    staticClass: "svg",
    on: {
      "click": function ($event) {
        return _vm.onTip(1);
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-sigh"
    }
  })], 1)]) : _vm._e()])]), _c('div', {
    staticClass: "fs-item"
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[2].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon item-icon-3"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tap-icon3.png`)
    }
  })]), _vm._m(3), _vm.progressList[2].isOk ? _c('div', {
    staticClass: "item-extend"
  }, [_vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.userData.extra_invite_count || 0, 2)) + " "), _c('div', {
    staticClass: "svg",
    on: {
      "click": function ($event) {
        return _vm.onTip(2);
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-sigh"
    }
  })], 1)]) : _vm._e()])]), _c('div', {
    staticClass: "fs-item"
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[3].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon item-icon-4"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tap-icon4.png`)
    }
  })]), _vm._m(4), _vm.progressList[3].isOk ? _c('div', {
    staticClass: "item-extend"
  }, [_c('div', {
    staticClass: "svg",
    on: {
      "click": function ($event) {
        return _vm.onTip(3);
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-sigh"
    }
  })], 1)]) : _vm._e()])]), _c('div', {
    staticClass: "fs-item"
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[4].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon item-icon-5"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tap-icon5.png`)
    }
  })]), _vm._m(5), _vm.progressList[4].isOk ? _c('div', {
    staticClass: "item-extend"
  }, [_c('div', {
    staticClass: "svg",
    on: {
      "click": function ($event) {
        return _vm.onTip(4);
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-sigh"
    }
  })], 1)]) : _vm._e()])]), _c('div', {
    staticClass: "fs-item"
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[5].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon item-icon-6"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tap-icon6.png`)
    }
  })]), _vm._m(6), _vm.progressList[5].isOk ? _c('div', {
    staticClass: "item-extend"
  }, [_c('div', {
    staticClass: "svg",
    on: {
      "click": function ($event) {
        return _vm.onTip(5);
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-sigh"
    }
  })], 1)]) : _vm._e()])])])])]), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1), _c('pop-airdrop-tap-tip', {
    attrs: {
      "show": _vm.popAirdropTapTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAirdropTapTipShow = $event;
      }
    }
  }), _c('pop-airdrop-tap-logs', {
    attrs: {
      "show": _vm.popAirdropTapLogsShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAirdropTapLogsShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/icons/tap.png"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("Profit per hour")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("Keys")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("Friends")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("Invitation Bonus")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("Game Activity")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("Official Media")])]);
}];
export { render, staticRenderFns };