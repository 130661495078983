var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "wallet page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "wallet-main"
  }, [_vm.theme !== 'memetown' ? _c('div', {
    staticClass: "total"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "total__icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/icon-pph-1.png`)
    }
  })]), _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "total__title"
  }, [_vm._v(_vm._s(_vm.$lang('Total profit per hour')))]), _c('div', {
    staticClass: "total__num"
  }, [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.totalHourEearnings, 2)))])])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "total__icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/icon-pph-2.png`)
    }
  })]), _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "total__title"
  }, [_vm._v(_vm._s(_vm.$lang('Profit per hour')))]), _c('div', {
    staticClass: "total__num"
  }, [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.hourEearnings, 2)))])])])]) : _vm._e(), _vm._l(_vm.list, function (citem, index) {
    return citem.assets.length > 0 ? _c('div', {
      staticClass: "token-items"
    }, [_c('div', {
      staticClass: "token-title"
    }, [_c('img', {
      attrs: {
        "src": citem.icon
      }
    }), _vm._v(_vm._s(citem.name) + " ")]), _vm._l(citem.assets, function (item, index) {
      return _c('div', {
        staticClass: "token-item"
      }, [_c('div', {
        staticClass: "balance-info"
      }, [_c('div', {
        staticClass: "balance__type"
      }, [_c('img', {
        attrs: {
          "src": item.icon
        }
      })]), _c('div', {
        staticClass: "balance__num"
      }, [_vm._v(_vm._s(_vm._f("nFormatter")(item.balance, 2)))])]), _c('div', {
        staticClass: "btns"
      }, [_c('div', {
        staticClass: "btn",
        class: !item.withdrawable && 'btn-d',
        on: {
          "click": function ($event) {
            item.withdrawable && _vm.onWithdraw(citem, item);
          }
        }
      }, [_vm._v(" " + _vm._s(_vm.$lang("Withdraw")) + " "), !item.withdrawable ? _c('div', {
        staticClass: "tip"
      }, [_vm._v("SOON")]) : _vm._e()]), item.proof_url ? _c('div', {
        staticClass: "icon",
        on: {
          "click": function ($event) {
            return _vm.onProolUrl(item.proof_url);
          }
        }
      }, [_c('img', {
        attrs: {
          "src": item.proof_button_image
        }
      })]) : _vm._e()])]);
    })], 2) : _vm._e();
  })], 2), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };